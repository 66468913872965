import React from 'react'
import Countdown from 'react-countdown-now'
import Radium from 'radium'

const style = {
    console: {
        fontFamily: 'Fira Mono',
        width: `700px`,
        height: `450px`,
        boxSizing: `border-box`,
        margin: `auto`,
        justifyContent: `center`,
    },
    header: {
        borderTopLeftRadius: '15px',
        borderTopRightRadius: `15px`,
        backgroundColor: `#555`,
        height: `45px`,
        lineHeight: `45px`,
        textAlign: `center`,
        color: `#DDD`,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`
    },
    body: {
        borderBottomLeftRadius: `15px`,
        borderBottomRightRadius: `15px`,
        boxSizing: `border-box`,
        padding: `20px`,
        height: `calc(100% - 30px)`,
        overflow: `scroll`,
        backgroundColor: `rgba(0, 0, 0, 0.8)`,
        color: `#FFFFFF`,

    },
    p: {
        lineHeight: `1.5rem`
    }

}

const styleDots = {
    dots: {
        height: `1rem`,
        width: `1rem`,
        borderRadius: `50%`
    },
    green: {
        backgroundColor: `#2fc150`
    },
    red: {
        backgroundColor: `#e6644c`
    },
    yellow: {
        backgroundColor: `#ffc107`
    }
}
const Dots = () => {

    return (
        <div css={{ display: `flex`, margin: `1rem`, height: `10px`, maxWidth: `70px`, width: `100%`, justifyContent: `space-between` }}>
            <span css={[styleDots.dots, styleDots.red]}></span>
            <span css={[styleDots.dots, styleDots.green]}></span>
            <span css={[styleDots.dots, styleDots.yellow]}></span>
        </div>
    )
}

const renderer = ({ days, hours, minutes, seconds }) => {
    return <span> {days}d :: {hours}h :: {minutes}m :: {seconds}s</span>
}

var cursorKeyframes = Radium.keyframes({
    'from': { color: 'transparent)' },
    'to': { color: 'transparent' },
    '50%': { color: 'rgba(255, 255, 255)' },
}, 'cursorPulse')

const styles = {
    cursor: {
        animation: 'x 1.5s linear infinite',
        animationName: cursorKeyframes
    }
}

class Console extends React.Component {

    render() {

        const date = this.props.date
        const formattedDate = new Date(this.props.date).toDateString()
        return (
            <div css={style.console}>
                <header css={[style.console, style.header]}>
                    <Dots />
                    <p css={{ display: `flex`, alignItems: `center`, }}></p>
                </header>
                <div css={style.body}>
                    <p css={style.console.p}>> Mark Your Calendars!</p>
                    <p css={style.console.p}>> For the 19th Annual Citywide Holdup</p>
                    <p>> {formattedDate}</p>
                    <p> >
                        <Countdown renderer={renderer} date={date} />
                    </p>

                    <p>>
                        <span style={styles.cursor}> | </span>
                    </p>
                </div>

            </div >
        )
    }
}

export default Radium(Console)