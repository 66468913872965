import React from 'react'
import Container from '../components/container'
import background from '../assets/img/citywide-splash-optimised.svg'
import Layout from '../components/layout'
import Console from '../components/console'
import { graphql } from 'gatsby'

class IndexPage extends React.Component {


    render() {
        const date = this.props.data.allDatoCmsIndexPage.edges[0].node.countdownDate
        return (
            < Layout location={this.props.location} css={{ height: `100vh` }}>
                <Container css={{
                    justifyContent: `flex-start`, alignItems: `center`,
                    marginTop: `3.5rem`, flexDirection: `column`,
                    background: `rgba(255, 246, 230, 0.81) url(${background})`,
                    backgroundSize: `cover`,
                    height: `100vh`,
                    width: `100vw`,
                    overflowY: `hidden`,
                    margin: `auto`
                }}>
                    <Console date={date}>

                        <h1 css={{ marginTop: `2rem` }}>Mark Your Calendars!</h1>
                    </Console>
                </Container>
            </Layout >
        )
    }
}
export default IndexPage

export const query = graphql`
        query IndexQuery{
            allDatoCmsIndexPage {
                edges {
                    node {
                        countdownDate
                    }
                }
            }
        }

    `